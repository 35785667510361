<template>
	<el-container style="height: 100vh; background-color: #f1f4f7;">
		<el-header height="42px" class="header-bar">
			<span>分流宝</span>
			<span class="ml-auto">{{LoginName}}</span>
		</el-header>
		<el-container style="overflow: hidden;">
			<el-aside width="226px" style="background-color: #fff; box-shadow: 3px 0 3px rgba(0,0,0,0.05);">
				<el-menu :default-active="defaultActive" router text-color="#30a5ff">
					<el-menu-item index="/home">
						<i class="el-icon-s-help"></i>
						<span slot="title">欢迎</span>
					</el-menu-item>
					<el-menu-item index="/tools">
						<i class="el-icon-menu"></i>
						<span slot="title">工具</span>
					</el-menu-item>
					<el-menu-item index="/members" v-if="Hello">
						<i class="el-icon-menu"></i>
						<span slot="title">会员管理</span>
					</el-menu-item>
					<el-menu-item index="/password">
						<i class="el-icon-info"></i>
						<span slot="title">密码</span>
					</el-menu-item>
					<el-divider class="my-6"></el-divider>
					<el-menu-item @click="logout">
						<i class="el-icon-user-solid"></i>
						<span slot="title">退出</span>
					</el-menu-item>
			</el-menu>

			<div class="copyright">版权信息</div>
			</el-aside>
			<div class="flex flex-col" style="width: 100%;">
				<div class="breadcrumb">
					<el-breadcrumb separator="/">
						<el-breadcrumb-item :to="{ path: '/' }">
							<i class="el-icon-s-home" style="color:#30a5ff"></i>
						</el-breadcrumb-item>
						<el-breadcrumb-item>
							<span>{{defaultActiveName}}</span>
						</el-breadcrumb-item>
					</el-breadcrumb>
				</div>

				<div class="fl-main">
					<router-view></router-view>
				</div>
			</div>

		</el-container>
	</el-container>
</template>

<script>
import {setSession, getSession, clearSession, hasKey} from '../session/index'
export default {
	data() {
		const item = {
			date: '2016-05-02',
			name: '王小虎',
			address: '上海市普陀区金沙江路 1518 弄'
		};
		return {
			currentMenu: '',
			activeName: 'tab1',
			activeName2: 'tab01',
			showList: false,
			tableData: Array(20).fill(item)
		}
	},
	watch: {
		'$route' (to, from) {

			this.getCurrentMenu()
		}
	},
	computed: {
        defaultActive() {
            // return '/' + this.$route.path.split('/').reverse()[0];
            return '/' + this.$route.path.split('/')[1];
        },
		defaultActiveName() {
            return  this.$route.meta.title
        },
		Hello(){
			if(sessionStorage.getItem("Hello") == `Hello`){
				return true
			}else{
				return false
			}
		},
		LoginName(){
			return sessionStorage.getItem("LoginName")
		}
    },
	methods: {
		handleChange(tab, event) {
			if (this.activeName2 == 'tab00') {
				this.showList = false;
			}
		},
		getCurrentMenu () {
			// 获取当前页面 菜单刷新后能自动选中
			let currentUrl = window.location.href
			let currentPage = currentUrl.split('/')[3].replace('#', '')
			this.currentMenu = currentPage
		},
		view() {
			this.showList = true;
			this.activeName2 = 'tab01'
		},
		logout() {
			this.$confirm('确定退出吗？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$router.push('/')
				this.$message({
					type: 'success',
					message: '退出成功!'
				});
			}).catch(() => {

			});
		},
	},
};
</script>

<style>
.el-menu-item, .el-submenu__title{
	height: 34px;
	line-height: 34px;
}
.el-menu-item.is-active{
	background-color: #30a5ff;
	color:#ffffff;
}
.el-menu-item [class^=el-icon-]{
	color: #30a5ff;
}
.header-bar {
	color: #30a5ff;
	background-color: #222222;
	display: flex;
	line-height: 42px;
}

.text-blue {
	color: #30a5ff;
}

.welcome {
	height: 34px;
	line-height: 34px;
	padding-left: 10px;
	background-color: #e9ecf2;
	color: #30a5ff;
}

.breadcrumb {
	background-color: #e9ecf2;
	height: 34px;
	display: flex;
	align-items: center;
	padding: 0 12px;
	flex-shrink: 0;
	border-bottom: 1px solid #e5e8ea;
	box-shadow: inset 3px 0 3px rgba(0, 0, 0, 0.05)
}

.fl-main {
	flex: 1;
	overflow: auto;
	padding: 12px;
}
.copyright{
	border-top:1px solid #eeeeee; height: 40px; line-height: 40px;
	position: absolute;
	bottom: 0;
	width: 100%;
	text-align: center;
	color: #999;
}
</style>
