<template>
	<div class="login-page">
		
		<div class="login-form">
			<div class="login-title">
				分流宝
			</div>
			<div class="mb-6">
				<input placeholder="账户" type="text" v-model="loginForm.username">
			</div>
			<div class="mb-6">
				<input placeholder="密码"  type="password"  v-model="loginForm.password">
			</div>
			<div>
				<button @click="handleLogin" class="login-btn">登录</button>
				<!-- <el-button :loading="loading" type="primary" style="width:100%;margin-bottom:30px;"
          			@click.native.prevent="handleLogin">登录</el-button> -->
			</div>
			<!-- <div class="text-right mt-3">
				<span style="cursor: pointer;">注册</span>
			</div> -->
		</div>
		
	</div>
</template>

<script>
import axios from 'axios'
import {setSession, getSession, clearSession, hasKey} from '../session/index'
export default {
	data() {
		return {
			loginForm: {
				username: '',
				password: ''
			},
		}
	},
	methods: {
		login() {
			this.$router.push('/')
		},
		handleLogin() {
			let url = '/AdminApi/AdminLogin'
			axios.post(header + location.host + url, this.loginForm).then((res)=>{
				if(res.data.LoginCode == 200){
				this.$message({
					message: "欢迎回来！",
					type: 'success'
				})
		
				let token = res.data.Token
				sessionStorage.setItem("Token", token)
				setSession("LoginName", this.loginForm.username)
				setSession("Hello", res.data.Hello)
				location.href = header + location.host + "/home"
				}else{
				this.$message({
					message: res.data,
					type: 'warning'
				})
				}
			})
		},
	}
}
</script>

<style>
	.login-page {
		height: 100vh;
		background-color: red;
		background-image: url('../assets/img/2.jpeg');
		background-size: cover;
	}
	.login-form{
		color: #ffffff;
		text-align: center;
		width: 310px;
		margin: 0 auto;
		padding-top: 120px;
	}
	.login-form input{
		width: 100%;
		height: 42px;
		border: 1px solid rgba(255,255,255,0.5);
		background-color: rgba(255,255,255,0);
		color: #ffffff;
		border-radius: 4px;
		padding: 0 15px;
	}
	.login-form input::placeholder{
		color: #ffffff;
	}
	.login-title{
		font-size: 30px;
		font-weight: bold;
		margin-bottom: 20px;
	}
	.login-btn{
		width: 100%;
		height: 44px;
		background-color: #d14a24;
		border-radius: 4px;
		border: 1px solid #e2691f;
		background-image: linear-gradient(0deg,hsl(13, 100%, 74%), #d24e29, #d96848 ) ;
		box-shadow: 0 5px 5px rgba(0,0,0,0.2);
	}
</style>
